import React from 'react';
import HomepageSlider from '../../../components/Slider';
import './HomepageAssociatedBrands.module.scss';
import { ASSOCIATED_BRANDS } from '../../../shared/constants';

const HomepageAssociatedBrands = (props) => {
  const { isDesktop } = props;

  return (
    <div className='assosicated-brands-wrapper'>
      <div className='assosicated-brands-container'>
        <h2 className='assosicated-brands-title'>Bandingkan Asuransi Terkemuka dalam Satu Platform </h2>
        <div className='assosicated-brands-section'>
          {
            isDesktop && (<AssociatedBrandListDesktop />)
          }
          {
            !isDesktop && (<AssociatedBrandListMobile />)
          }
        </div>
      </div>
    </div>
  );
}

const AssociatedBrandListDesktop = () => {

  return (
    <div className='assosicated-brands-list'>
      {
        ASSOCIATED_BRANDS.map((item, index) => (
          <div className='assosicated-brands-item' key={`assosicated-brand-image-${index}`}>
            <a target='_blank' href={item.url}><img
              src={item.icon}
              alt="" /></a>
          </div>
        ))
      }
    </div>
  );
}

const AssociatedBrandListMobile = () => {
  const [
    BRAND_ASTRA_ICON,
    BRAND_ZURICH_SYARIAH_ICON,
    BRAND_ACA_ICON,
    BRAND_SOMPO_BY_ROOJAI_ICON,
    BRAND_SIMAS_INSURTECH_ICON,
    BRAND_ASURANSI_SINARMAS,
    BRAND_MEGA_ICON,
    BRAND_AXA_MANDIRI_ICON,
    BRAND_SUN_LIFE_ICON,
    BRAND_LGI_ICON,
    BRAND_APRIL_ICON,
    BRAND_KB_ICON,
    BRAND_MAG_ICON,
    BRAND_CAR_LIFE_ICON
  ] = ASSOCIATED_BRANDS

  const items = [
    [
      BRAND_ASTRA_ICON,
      BRAND_ZURICH_SYARIAH_ICON,
      BRAND_ACA_ICON,
    ],
    [
      BRAND_SOMPO_BY_ROOJAI_ICON,
      BRAND_SIMAS_INSURTECH_ICON,
      BRAND_ASURANSI_SINARMAS,
      
    ],
    [
      BRAND_MEGA_ICON,
      BRAND_AXA_MANDIRI_ICON,
      BRAND_SUN_LIFE_ICON,
      
    ],
    [
      BRAND_LGI_ICON,
      BRAND_APRIL_ICON,
      BRAND_KB_ICON,
    ],
    [
      BRAND_MAG_ICON,
      BRAND_CAR_LIFE_ICON
    ],
  ];

  return (
    <HomepageSlider
      sliderData={{
        desktop: {
          height: 224,
          width: 148,
          visibleItems: 2,
        },
        mobile: {
          height: 200,
          width: 136,
          visibleItems: 2,
        },
      }}
      items={
        items.map((brands, index) => (
          <div className='assosicated-brands-list' key={`associated-brand-${index}`}>
            {
              brands.map((brand, index) => (
                <div
                  key={`assosicated-brand-image-${index}`}
                  className='assosicated-brands-item'
                >
                  <a target='_blank' href={brand.url}><img
                    src={brand.icon}
                    alt={`assosicated-brands-item-${index}`} /></a>
                </div>
              ))
            }
          </div>
        ))
      }
    />
  );
}

export default HomepageAssociatedBrands;